import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import "./index.scss";
import App from 'containers/App';
import { withGATracker } from "hocs/withGaTracker";

ReactDOM.render(
  <Router>
    <Route component={withGATracker(App)} />
  </Router>, document.getElementById('root'));
