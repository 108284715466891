import React from "react";
import { I18n } from "components/I18n";
import { ContactButton } from "components/ContactButton";
import "./index.scss";

export const ContactBlock = () => (
  <div className="container container--dark container--100vh contact-block">
    <div className="contact-block__title-question"><I18n id="MAIN_PAGE_CONTACT_BLOCK_QUESTION"/></div>
    <div className="contact-block__title-answer"><I18n id="MAIN_PAGE_CONTACT_BLOCK_ANSWER"/></div>
    <div className="contact-block__divider" />
    <div className="contact-block__subtitle"><I18n id="MAIN_PAGE_CONTACT_BLOCK_SUBTITLE"/></div>
    <ContactButton className="btn btn--white"><I18n id="MAIN_PAGE_CONTACT_BLOCK_CONTACT_BTN"/> </ContactButton>
  </div>
);
