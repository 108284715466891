import React, { useMemo } from "react";
import { I18n } from "components/I18n";
import "./index.scss";
import { useCarousel } from "hooks";
import videoSecurity from "assets/videoSecurity.mp4";
import videoFinance from "assets/videoFinance.mp4";
import videoMarketing from "assets/videoMarketing.mp4";
import { HeaderClassNameContext } from "components/Header";

const items = [
  {
    videoSrc: videoSecurity,
    containerClass: "container--red",
    tabName: <I18n id="MAIN_BLOCK_TAB_NAME_SECURITY" />,
    title: <I18n id="MAIN_BLOCK_TAB_NAME_SECURITY_TITLE" />,
    firstSubtext: <I18n id="MAIN_BLOCK_TAB_NAME_SECURITY_FIRST_SUBTEXT" />,
    secondSubtext: <I18n id="MAIN_BLOCK_TAB_NAME_SECURITY_SECOND_SUBTEXT" />,
    headerClassName: "header--red"
  },
  {
    videoSrc: videoFinance,
    containerClass: "container--dark",
    tabName: <I18n id="MAIN_BLOCK_TAB_NAME_FINANCE" />,
    title: <I18n id="MAIN_BLOCK_TAB_NAME_FINANCE_TITLE" />,
    firstSubtext: <I18n id="MAIN_BLOCK_TAB_NAME_FINANCE_FIRST_SUBTEXT" />,
    secondSubtext: <I18n id="MAIN_BLOCK_TAB_NAME_FINANCE_SECOND_SUBTEXT" />,
    headerClassName: "header--dark"
  },
  {
    videoSrc: videoMarketing,
    containerClass: "container--blue",
    tabName: <I18n id="MAIN_BLOCK_TAB_NAME_MARKETING" />,
    title: <I18n id="MAIN_BLOCK_TAB_NAME_MARKETING_TITLE" />,
    firstSubtext: <I18n id="MAIN_BLOCK_TAB_NAME_MARKETING_FIRST_SUBTEXT" />,
    secondSubtext: <I18n id="MAIN_BLOCK_TAB_NAME_MARKETING_SECOND_SUBTEXT" />,
    headerClassName: "header--blue"
  }
];

const MainBlockCmp = ({ setHeaderClassName }) => {
  const onChange = useMemo(
    () => index => setHeaderClassName(items[index].headerClassName),
    [setHeaderClassName]
  );
  const onUnmount = useMemo(() => () => setHeaderClassName(""), [
    setHeaderClassName
  ]);
  const [currentIndex, updateIndex] = useCarousel(items, onChange, onUnmount);

  return (
    <div
      className={`container container--100vh ${items[currentIndex].containerClass} main-block`}
    >
      <div className="two-column-desktop main-block__video">
        <div className="main-block__video__content">
          <video
            className="fake"
            preload="auto"
            autoPlay
            muted
            loop
            playsInline
            src={items[0].videoSrc}
          />
          {items.map((item, index) => (
            <video
              key={index}
              className="visible"
              style={{ display: index === currentIndex ? "block" : "none" }}
              preload="auto"
              autoPlay
              muted
              loop
              playsInline
              src={item.videoSrc}
            />
          ))}
        </div>
      </div>
      <div className="two-column-desktop main-block__video-description-wrapper">
        <div className="main-block__video-description">
          <div className="main-block__tabs">
            {items.map((item, index) => (
              <button
                key={index}
                className={`main-block__tab-name ${
                  index === currentIndex ? "active" : ""
                }`}
                onClick={() => updateIndex(index)}
              >
                {item.tabName}
              </button>
            ))}
          </div>
          <div className="main-block__title">{items[currentIndex].title}</div>
          <div className="flex">
            <div className="two-column-tablet main-block__subtitle">
              {items[currentIndex].firstSubtext}
            </div>
            <div className="two-column-tablet main-block__subtitle">
              {items[currentIndex].secondSubtext}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MainBlock = () => (
  <HeaderClassNameContext.Consumer>
    {setHeaderClassName => {
      return <MainBlockCmp setHeaderClassName={setHeaderClassName} />;
    }}
  </HeaderClassNameContext.Consumer>
);
