import React from "react";
import ReactModal from "react-modal";
import { ReactComponent as CloseIcon } from "assets/closeIcon.svg";
import { ReactComponent as CloseInvertedIcon } from "assets/closeIconInverted.svg";
import { NavLink } from "react-router-dom";
import { LanguageSwitcher } from "components/LanguageSwitcher";
import './index.scss';
import { ReactComponent as LogoInverted } from "assets/headerLogoInverted.svg";
import { ReactComponent as Logo } from "assets/headerLogo.svg";

ReactModal.setAppElement("#root");

export const MobileMenuModal = ({
  isOpened,
  setIsOpened,
  headerLinks,
  setLocale,
  currentLocale,
  className
}) => {
  const isInverted = false;
  const CloseIconCmp = isInverted ? CloseInvertedIcon : CloseIcon;
  const LogoCmp = isInverted ? LogoInverted : Logo;
  return (
    <ReactModal
      onRequestClose={() => setIsOpened(false)}
      overlayClassName="mobile-menu-modal-overlay"
      className={`mobile-menu-modal-content' ${className} ${isInverted ? "header--inverted" : ""}`}
      isOpen={isOpened}
    >
      <div className="header__mobile-menu">
        <div className="header__logo">
          <NavLink to="/" exact>
            <LogoCmp />
          </NavLink>
        </div>
        <CloseIconCmp
          onClick={() => setIsOpened(!isOpened)}
          className="header__close-icon"
        />
        <div className="header__nav-mobile">
          {headerLinks.map(({ name, to, exact }) => (
            <NavLink
              key={to}
              to={to}
              exact={exact}
              onClick={() => setIsOpened(!isOpened)}
            >
              {name}
            </NavLink>
          ))}
        </div>
        <LanguageSwitcher {...{ setLocale, currentLocale, isInverted }} />
      </div>
    </ReactModal>
  );
};
