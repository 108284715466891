import React from "react";
import { I18n } from "components/I18n";
import "./index.scss";
import { ContactButton } from "components/ContactButton";

const contactLinks = [
  {
    name: "Facebook",
    url: "https://facebook.com/bitnag.inc"
  },
  {
    name: "Twitter",
    url: "https://twitter.com/bitnag"
  },
  {
    name: "LinkedIn",
    url: "https://linkedin.com/company/bitnag/"
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/bitnag.inc/"
  },
  {
    name: "Telegram",
    url: "https://telegram.me/bitnag_bot"
  },
  {
    name: "Email",
    url: "mailto:support@bitnag.com"
  }
];

export const ContactPage = () => (
  <div className="container container--top container--100vh contact-page">
    <div className="two-column-desktop contact-page__title">
      <I18n id="CONTACT_PAGE_FIRST_TITLE" />
    </div>
    <div className="two-column-desktop">
      <div className="contact-page__contacts-block__title">
        {contactLinks.map((link, index) => (
          <>
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              {link.name}
            </a>
            {index !== contactLinks.length - 1 ? ", " : ""}
          </>
        ))}
      </div>
      <div className="contact-page__contacts-block__subtitle">
        <I18n id="CONTACT_PAGE_SUBTITLE" />
      </div>
      <ContactButton className="btn btn--blue">
        <I18n id="CONTACT_PAGE_CONTACT_BTN" />
      </ContactButton>
    </div>
  </div>
);
