import { createContext } from "react";

const DEFAULT_LANG = 'en';

const LocaleContext = createContext({
  locale: DEFAULT_LANG
});

export const IntlProvider = LocaleContext.Provider;

export const LocaleConsumer = LocaleContext.Consumer;
