import React from "react";
import { I18n } from "components/I18n";
import "./index.scss";

const accomplishedServices = [{
  title: <I18n id="SERVICE_PAGE_ACCOMPLISHED_SERVICES_LIST_RETAIL_TITLE" />,
  description: <I18n  id="SERVICE_PAGE_ACCOMPLISHED_SERVICES_LIST_RETAIL_DESCRIPTION" />
},{
  title: <I18n id="SERVICE_PAGE_ACCOMPLISHED_SERVICES_LIST_AUTOMOTIVE_TITLE" />,
  description: <I18n  id="SERVICE_PAGE_ACCOMPLISHED_SERVICES_LIST_AUTOMOTIVE_DESCRIPTION" />
},{
  title: <I18n id="SERVICE_PAGE_ACCOMPLISHED_SERVICES_LIST_SECURITY_TITLE" />,
  description: <I18n  id="SERVICE_PAGE_ACCOMPLISHED_SERVICES_LIST_SECURITY_DESCRIPTION" />
},{
  title: <I18n id="SERVICE_PAGE_ACCOMPLISHED_SERVICES_LIST_BANKING_TITLE" />,
  description: <I18n  id="SERVICE_PAGE_ACCOMPLISHED_SERVICES_LIST_BANKING_DESCRIPTION" />
},{
  title: <I18n id="SERVICE_PAGE_ACCOMPLISHED_SERVICES_LIST_OFFICES_TITLE" />,
  description: <I18n  id="SERVICE_PAGE_ACCOMPLISHED_SERVICES_LIST_OFFICES_DESCRIPTION" />
},{
  title: <I18n id="SERVICE_PAGE_ACCOMPLISHED_SERVICES_LIST_TELECOM_TITLE" />,
  description: <I18n  id="SERVICE_PAGE_ACCOMPLISHED_SERVICES_LIST_TELECOM_DESCRIPTION" />
}];





export const AccomplishedServicesBlock = () => (
  <div className="container accomplished-services-block">
    <div className="two-column-desktop accomplished-services-block__title">
      <I18n id="SERVICE_PAGE_ACCOMPLISHED_SERVICES_BLOCK_TITLE" />
    </div>
    <div className="two-column-desktop accomplished-services-block__subtitle">
      <I18n id="SERVICE_PAGE_ACCOMPLISHED_SERVICES_BLOCK_SUBTITLE" />
    </div>
    <div className="accomplished-services-block__list">
      {accomplishedServices.map(({ title, description }) => (
        <div className="accomplished-services-block__list-item">
          <div className="accomplished-services-block__list-item__title">{title}</div>
          <div className="accomplished-services-block__list-item__description">{description}</div>
        </div>
      ))}
    </div>
  </div>
);
