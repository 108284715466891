import React, { useState, useMemo } from "react";
import { ContactModal } from './ContactModal';

const sendContactRequest = (values) => {
  return window.fetch('/api/contact', {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(values)
  })
};

export const withContactModal = BaseComponent => props => {
  const [isOpen, setIsOpen] = useState(false);
  const contactModalProps = useMemo(() => ({
    toggle: () => setIsOpen(isOpen => !isOpen),
    close: () => setIsOpen(false)
  }), []);

  return (
    <>
      <BaseComponent {...props} contactModalProps={contactModalProps} />
      <ContactModal isOpen={isOpen} close={contactModalProps.close} onSubmit={sendContactRequest} />
    </>
  );
};
