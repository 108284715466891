import React, { useState } from "react";
import {
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { Header, HeaderClassNameContext } from "components/Header";
import { Footer } from "components/Footer";
import { MainPage } from "containers/MainPage";
import { ServicesPage } from "containers/ServicesPage";
import { IntlProvider } from "containers/Intl";
import { AboutPage } from "containers/AboutPage";
import { ContactPage } from "containers/ContactPage";

const App = () => {
  const [currentLocale, setLocale] = useState("en");
  const [className, setHeaderClassName] = useState('');
  return (
    <IntlProvider value={currentLocale}>
      <HeaderClassNameContext.Provider value={setHeaderClassName}>
      <Header className={className} setLocale={setLocale} currentLocale={currentLocale} />
      <Switch>
        <Route path="/" exact component={MainPage} />
        <Route path="/services" exact component={ServicesPage} />
        <Route path="/about" exact component={AboutPage} />
        <Route path="/contact" exact component={ContactPage} />
        <Redirect to="/" />
      </Switch>
      <Footer />
      </HeaderClassNameContext.Provider>
    </IntlProvider>
  );
};

export default App;
