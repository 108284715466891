import { useState, useEffect, useMemo } from 'react';

const _noop = () => null;

export const useCarousel = (items, onChange = _noop, onUnmount = _noop) => {
  const [currentIndex, setCurrentItem] = useState(0);
  useEffect(() => {
    onChange(currentIndex);

    return onUnmount;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const updateFnID = setInterval(
      () => {
        const itemIndex = (currentIndex + 1) % items.length;
        setCurrentItem(itemIndex);
        onChange(itemIndex);
      },
      10000
    );

    return () => {
      clearInterval(updateFnID);
    }
  }, [currentIndex, items, onChange]);


  const updateIndex = useMemo(() => index => {
    setCurrentItem(index);
    onChange(index);
  }, [onChange, setCurrentItem]);
  return [currentIndex, updateIndex];
};
