import React, { useMemo } from "react";
import "./index.scss";

const AVAILABLE_LOCALES = ['en', 'ru'];

export const LanguageSwitcher = ({ className = '', setLocale, currentLocale, isInverted = false }) => {
  const onLocaleClick = useMemo(() => () => {
    const currentIndex = AVAILABLE_LOCALES.findIndex((i) => i === currentLocale);
    setLocale(AVAILABLE_LOCALES[(currentIndex + 1) % AVAILABLE_LOCALES.length]);
  }, [setLocale, currentLocale]);
  return (
    <div className={`language-switcher ${className} ${isInverted ? 'language-switcher--inverted' : ''}`}>
      <div onClick={onLocaleClick}>{currentLocale}</div>
    </div>
  );
};
