import React from "react";
import { ServiceMainBlock } from "./ServiceMainBlock";
import { AccomplishedServicesBlock } from "./AccomplishedServicesBlock";
import { ContactBlock } from "./ContactBlock";

export const ServicesPage = () => (
  <>
    <ServiceMainBlock />
    <AccomplishedServicesBlock />
    <ContactBlock />
  </>
);
