import React from "react";
import { I18n } from "components/I18n";
import "./index.scss";
import { useCarousel } from "hooks";
import { ContactButton } from "components/ContactButton";
import benefitsOfficeAlarmImage from "assets/images/benefitsOfficeAlarm.jpg";
import benefitsOfficeAlarmImage2x from "assets/images/benefitsOfficeAlarm@2x.jpg";
import benefitsPACSImage from "assets/images/benefitsPACS.jpg";
import benefitsPACSImage2x from "assets/images/benefitsPACS@2x.jpg";
import benefitsSpecialAlarmImage from "assets/images/benefitsSpecialAlarm.jpg";
import benefitsSpecialAlarmImage2x from "assets/images/benefitsSpecialAlarm@2x.jpg";

const items = [
  {
    imageSrcSet: `${benefitsOfficeAlarmImage}, ${benefitsOfficeAlarmImage2x} 2x`,
    tabName: <I18n id="MAIN_PAGE_BENEFITS_BLOCK_TAB_NAME_OFFICE_ALARM" />,
    title: <I18n id="MAIN_PAGE_BENEFITS_BLOCK_TAB_NAME_OFFICE_ALARM_TITLE" />,
    firstSubtext: (
      <I18n id="MAIN_PAGE_BENEFITS_BLOCK_TAB_NAME_OFFICE_ALARM_FIRST_SUBTEXT" />
    ),
    secondSubtext: (
      <I18n id="MAIN_PAGE_BENEFITS_BLOCK_TAB_NAME_OFFICE_ALARM_SECOND_SUBTEXT" />
    )
  },
  {
    imageSrcSet: `${benefitsPACSImage}, ${benefitsPACSImage2x} 2x`,
    tabName: <I18n id="MAIN_PAGE_BENEFITS_BLOCK_TAB_NAME_PACS" />,
    title: <I18n id="MAIN_PAGE_BENEFITS_BLOCK_TAB_NAME_PACS_TITLE" />,
    firstSubtext: (
      <I18n id="MAIN_PAGE_BENEFITS_BLOCK_TAB_NAME_PACS_FIRST_SUBTEXT" />
    ),
    secondSubtext: (
      <I18n id="MAIN_PAGE_BENEFITS_BLOCK_TAB_NAME_PACS_SECOND_SUBTEXT" />
    )
  },
  {
    imageSrcSet: `${benefitsSpecialAlarmImage}, ${benefitsSpecialAlarmImage2x} 2x`,
    tabName: <I18n id="MAIN_PAGE_BENEFITS_BLOCK_TAB_NAME_SPECIAL_ALARM" />,
    title: <I18n id="MAIN_PAGE_BENEFITS_BLOCK_TAB_NAME_SPECIAL_ALARM_TITLE" />,
    firstSubtext: (
      <I18n id="MAIN_PAGE_BENEFITS_BLOCK_TAB_NAME_SPECIAL_ALARM_FIRST_SUBTEXT" />
    ),
    secondSubtext: (
      <I18n id="MAIN_PAGE_BENEFITS_BLOCK_TAB_NAME_SPECIAL_ALARM_SECOND_SUBTEXT" />
    )
  }
];

export const BenefitsBlock = () => {
  const [currentIndex, updateIndex] = useCarousel(items);

  return (
    <div className="container benefits-block">
      <div className="two-column-desktop carousel-image">
        <img alt="" srcSet={items[currentIndex].imageSrcSet} />
      </div>
      <div className="two-column-desktop">
        <div className="carousel-description">
          <div className="benefits-block__tabs">
            {items.map((item, index) => (
              <button
                key={index}
                className={`benefits-block__tab-name ${
                  index === currentIndex ? "active" : ""
                }`}
                onClick={() => updateIndex(index)}
              >
                {item.tabName}
              </button>
            ))}
          </div>
          <div className="benefits-block__tabs-title">
            {items[currentIndex].title}
          </div>
          <div className="flex">
            <div className="two-column-tablet benefits-block__tabs-subtitle">
              {items[currentIndex].firstSubtext}
            </div>
            <div className="two-column-tablet benefits-block__tabs-subtitle">
              {items[currentIndex].secondSubtext}
            </div>
          </div>
          <ContactButton className="btn btn--blue">
            <I18n id="MAIN_PAGE_BENEFITS_BLOCK_TRY_BTN" />
          </ContactButton>
        </div>
      </div>
    </div>
  );
};
