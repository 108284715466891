import React, { useState, createContext } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { ReactComponent as Logo } from "assets/headerLogo.svg";
import { ReactComponent as LogoInverted } from "assets/headerLogoInverted.svg";
import { ReactComponent as HamburgerIcon } from "assets/hamburger.svg";
import { ReactComponent as HamburgerInvertedIcon } from "assets/hamburgerInverted.svg";
import { I18n } from "components/I18n";
import "./header.scss";
import { LanguageSwitcher } from "components/LanguageSwitcher";
import { ContactButton } from "components/ContactButton";
import { MobileMenuModal } from "./MobileMenuModal";

const headerLinks = [{
  to: '/',
  exact: true,
  name: <I18n id="HEADER_NAV_HOME" />
}, {
  to: '/services',
  exact: false,
  name: <I18n id="HEADER_NAV_SERVICES" />
}, {
  to: '/about',
  exact: false,
  name: <I18n id="HEADER_NAV_ABOUT" />
}, {
  to: '/contact',
  exact: false,
  name: <I18n id="HEADER_NAV_CONTACT" />
}];

export const HeaderClassNameContext = createContext('');

export const Header = withRouter(({ className = '', location, setLocale, currentLocale }) => {
  const isInverted = location.pathname === "/";
  const LogoCmp = isInverted ? LogoInverted : Logo;
  const HamburgerCmp = isInverted ? HamburgerInvertedIcon : HamburgerIcon;
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className={`header ${className} ${isInverted ? "header--inverted" : ""}`}>
      <div className="header__logo">
        <NavLink to="/" exact>
          <LogoCmp />
        </NavLink>
      </div>
      <div className="header__nav hide-mobile">
        {headerLinks.map(({name, to, exact}) => (
          <NavLink key={to} to={to} exact={exact}>
            {name}
          </NavLink>
        ))}
      </div>
      <ContactButton
        className={`btn btn--header hide-mobile ${
          isInverted ? "btn--inverted" : "btn--blue"
        }`}
      >
        <I18n id="HEADER_CONTACT_BTN" />
      </ContactButton>
      <LanguageSwitcher className="hide-mobile" {...{ setLocale, currentLocale, isInverted }} />
      <HamburgerCmp className="show-mobile header__menu-icon" onClick={() => setIsOpened(!isOpened)} />
      <MobileMenuModal {...{ isOpened, className, setIsOpened, headerLinks, setLocale, currentLocale, isInverted, LogoCmp }} />
    </div>
  );
});
