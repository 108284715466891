import React from "react";
import { MainBlock } from "./MainBlock";
import { SecondBlock } from "./SecondBlock";
import { TechnologiesBlock } from "./TechnologiesBlock";
import { BenefitsBlock } from "./BenefitsBlock";
import { ContactBlock } from "./ContactBlock";

export const MainPage = () => (
  <>
    <MainBlock />
    <SecondBlock />
    <TechnologiesBlock />
    <BenefitsBlock />
    <ContactBlock />
  </>
);
