import React from "react";
import { I18n } from "components/I18n";
import "./index.scss";
import { useCarousel } from "hooks";
import { ContactButton } from "components/ContactButton";
import secondBlockAutomotiveImage from "assets/images/secondBlockAutomotive.jpg";
import secondBlockAutomotiveImage2x from "assets/images/secondBlockAutomotive@2x.jpg";
import secondBlockBankingImage from "assets/images/secondBlockBanking.jpg";
import secondBlockBankingImage2x from "assets/images/secondBlockBanking@2x.jpg";
import secondBlockRetailImage from "assets/images/secondBlockRetail.jpg";
import secondBlockRetailImage2x from "assets/images/secondBlockRetail@2x.jpg";

const items = [
  {
    imageSrcSet: `${secondBlockAutomotiveImage}, ${secondBlockAutomotiveImage2x} 2x`,
    tabName: <I18n id="MAIN_PAGE_SECOND_BLOCK_TAB_NAME_AUTOMOTIVE" />,
    title: <I18n id="MAIN_PAGE_SECOND_BLOCK_TAB_NAME_AUTOMOTIVE_TITLE" />,
    firstSubtext: (
      <I18n id="MAIN_PAGE_SECOND_BLOCK_TAB_NAME_AUTOMOTIVE_FIRST_SUBTEXT" />
    ),
    secondSubtext: (
      <I18n id="MAIN_PAGE_SECOND_BLOCK_TAB_NAME_AUTOMOTIVE_SECOND_SUBTEXT" />
    )
  },
  {
    imageSrcSet: `${secondBlockBankingImage}, ${secondBlockBankingImage2x} 2x`,
    tabName: <I18n id="MAIN_PAGE_SECOND_BLOCK_TAB_NAME_BANKING" />,
    title: <I18n id="MAIN_PAGE_SECOND_BLOCK_TAB_NAME_BANKING_TITLE" />,
    firstSubtext: (
      <I18n id="MAIN_PAGE_SECOND_BLOCK_TAB_NAME_BANKING_FIRST_SUBTEXT" />
    ),
    secondSubtext: (
      <I18n id="MAIN_PAGE_SECOND_BLOCK_TAB_NAME_BANKING_SECOND_SUBTEXT" />
    )
  },
  {
    imageSrcSet: `${secondBlockRetailImage}, ${secondBlockRetailImage2x} 2x`,
    tabName: <I18n id="MAIN_PAGE_SECOND_BLOCK_TAB_NAME_RETAIL" />,
    title: <I18n id="MAIN_PAGE_SECOND_BLOCK_TAB_NAME_RETAIL_TITLE" />,
    firstSubtext: (
      <I18n id="MAIN_PAGE_SECOND_BLOCK_TAB_NAME_RETAIL_FIRST_SUBTEXT" />
    ),
    secondSubtext: (
      <I18n id="MAIN_PAGE_SECOND_BLOCK_TAB_NAME_RETAIL_SECOND_SUBTEXT" />
    )
  }
];

export const SecondBlock = () => {
  const [currentIndex, updateIndex] = useCarousel(items);

  return (
    <div className="container second-block">
      <div className="second-block__title">
        <I18n id="MAIN_PAGE_SECOND_BLOCK_TITLE" />
      </div>
      <div className="second-block__subtitle">
        <I18n id="MAIN_PAGE_SECOND_BLOCK_SUBTITLE" />
      </div>
      <div className="two-column-desktop carousel-image">
        <img alt="" srcSet={items[currentIndex].imageSrcSet} />
      </div>
      <div className="two-column-desktop">
        <div className="carousel-description">
          <div className="second-block__tabs">
            {items.map((item, index) => (
              <button
                key={index}
                className={`second-block__tab-name ${
                  index === currentIndex ? "active" : ""
                }`}
                onClick={() => updateIndex(index)}
              >
                {item.tabName}
              </button>
            ))}
          </div>
          <div className="second-block__tabs-title">
            {items[currentIndex].title}
          </div>
          <div className="flex">
            <div className="two-column-tablet second-block__tabs-subtitle">
              {items[currentIndex].firstSubtext}
            </div>
            <div className="two-column-tablet second-block__tabs-subtitle">
              {items[currentIndex].secondSubtext}
            </div>
          </div>
          <ContactButton className="btn btn--blue">
            <I18n id="MAIN_PAGE_SECOND_BLOCK_TRY_BTN" />
          </ContactButton>
        </div>
      </div>
    </div>
  );
};
