import React, { useState, useMemo } from "react";
import ReactModal from "react-modal";
import "./index.scss";
import { ReactComponent as CloseIcon } from "assets/closeIcon.svg";
import { I18n } from "components/I18n";

ReactModal.setAppElement("#root");

export const ContactModal = ({ isOpen, close, onSubmit }) => {
  const [formValues, setFormValues] = useState({ text: "" });
  const [isSubmitted, setSubmitted] = useState(false);

  const onInputChange = useMemo(
    () => e => setFormValues({ [e.target.name]: e.target.value }),
    []
  );

  const onOkClick = useMemo(
    () => () => {
      setSubmitted(false);
      close();
    },
    [setSubmitted, close]
  );

  const onFormSubmit = useMemo(
    () => e => {
      e.preventDefault();
      onSubmit(formValues).then(() => {
        setSubmitted(true);
        setFormValues({ text: "" });
      });
    },
    [formValues, onSubmit, setSubmitted, setFormValues]
  );
  return (
    <ReactModal
      onRequestClose={close}
      overlayClassName="contact-modal-overlay"
      className={`contact-modal-content ${isSubmitted ? 'contact-modal-content--success' : ''}`}
      isOpen={isOpen}
    >
      <CloseIcon
        onClick={close}
        className="contact-modal-content__close-icon"
      />
      {!isSubmitted ? (
        <>
          <div className="contact-modal-content__title">
            <I18n id="CONTACT_MODAL_TITLE" />
          </div>
          <form onSubmit={onFormSubmit}>
            <input
              name="text"
              value={formValues.text}
              onChange={onInputChange}
              className="contact-modal-content__input"
              placeholder="Phone, Email, Messenger, Anything"
            />
            <button
              type="submit"
              disabled={!formValues.text}
              className="btn btn--blue"
            >
              <I18n id="CONTACT_MODAL_SUBMIT_BTN" />
            </button>
          </form>
        </>
      ) : (
        <>
          <div className="contact-modal-content__success-title">
            <I18n id="CONTACT_MODAL_SUCCESS_TITLE" />
          </div>
          <button className="btn btn--blue" onClick={onOkClick}>
            <I18n id="CONTACT_MODAL_SUCCESS_BTN" />
          </button>
        </>
      )}
    </ReactModal>
  );
};
