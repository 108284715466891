import React from "react";
import { I18n } from "components/I18n";
import "./index.scss";
import servicesFirstImage from "assets/images/servicesFirstImage.jpg";
import servicesFirstImage2x from "assets/images/servicesFirstImage@2x.jpg";
import serviceSecondImage from "assets/images/servicesSecondImage.jpg";
import serviceSecondImage2x from "assets/images/servicesSecondImage@2x.jpg";

export const ServiceMainBlock = () => (
  <div className="container container--top service-main-block">
    <div className="service-main-block__title">
      <I18n id="SERVICE_PAGE_MAIN_BLOCK_TITLE" />
    </div>
    <div className="service-main-block__subtitle">
      <I18n id="SERVICE_PAGE_MAIN_BLOCK_SUBTITLE" />
    </div>
    <div className="two-column-desktop">
      <img
        alt=""
        className="service-main-block__first-image"
        src={servicesFirstImage}
        srcSet={`${servicesFirstImage}, ${servicesFirstImage2x} 2x`}
      />
    </div>
    <div className="two-column-desktop">
      <img
        alt=""
        className="service-main-block__second-image"
        src={serviceSecondImage}
        srcSet={`${serviceSecondImage}, ${serviceSecondImage2x} 2x`}
      />
    </div>
  </div>
);
