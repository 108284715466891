import React from "react";
import { AboutInfoBlock } from "./AboutInfoBlock";
import { ContactBlock } from "./ContactBlock";

export const AboutPage = () => (
  <>
    <AboutInfoBlock />
    <ContactBlock />
  </>
);
