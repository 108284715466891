import React from "react";
import { I18n } from "components/I18n";
import "./index.scss";

const aboutInfoItems = [
  {
    title: <I18n id="ABOUT_PAGE_INFO_BLOCK_LIST_WHO_TITLE" />,
    description: <I18n id="ABOUT_PAGE_INFO_BLOCK_LIST_WHO_DESCRIPTION" />
  },
  {
    title: <I18n id="ABOUT_PAGE_INFO_BLOCK_LIST_SEGMENT_TITLE" />,
    description: <I18n id="ABOUT_PAGE_INFO_BLOCK_LIST_SEGMENT_DESCRIPTION" />
  },
  {
    title: <I18n id="ABOUT_PAGE_INFO_BLOCK_LIST_PRODUCT_TITLE" />,
    description: <I18n id="ABOUT_PAGE_INFO_BLOCK_LIST_PRODUCT_DESCRIPTION" />
  },
  {
    title: <I18n id="ABOUT_PAGE_INFO_BLOCK_LIST_TECHNOLOGY_TITLE" />,
    description: <I18n id="ABOUT_PAGE_INFO_BLOCK_LIST_TECHNOLOGY_DESCRIPTION" />
  }
];

export const AboutInfoBlock = () => (
  <div className="container container--top about-info-block">
    <div className="about-info-block__title">
      <I18n id="ABOUT_PAGE_INFO_BLOCK_TITLE" />
    </div>
    <div className="about-info-block__subtitle">
      <I18n id="ABOUT_PAGE_INFO_BLOCK_SUBTITLE" />
    </div>
    <div className="about-info-block__list">
      {aboutInfoItems.map(({ title, description }) => (
        <div className="about-info-block__list-item">
          <div className="two-column-desktop about-info-block__list-item__title">{title}</div>
          <div className="two-column-desktop about-info-block__list-item__description">
            {description}
          </div>
        </div>
      ))}
    </div>
  </div>
);
