import React, { Fragment } from "react";
import ReactTooltip from "react-tooltip";
import { I18n } from "components/I18n";
import "./index.scss";

const technologies = [
  {
    id: "facialRecognition",
    title: (
      <I18n id="MAIN_PAGE_TECHNOLOGIES_BLOCK_LIST_FACIAL_RECOGNITION_TITLE" />
    ),
    description: (
      <I18n id="MAIN_PAGE_TECHNOLOGIES_BLOCK_LIST_FACIAL_RECOGNITION_DESCRIPTION" />
    )
  },
  {
    id: "faceDetection",
    title: <I18n id="MAIN_PAGE_TECHNOLOGIES_BLOCK_LIST_FACE_DETECTION_TITLE" />,
    description: (
      <I18n id="MAIN_PAGE_TECHNOLOGIES_BLOCK_LIST_FACE_DETECTION_DESCRIPTION" />
    )
  },
  {
    id: "antiSpoofing",
    title: <I18n id="MAIN_PAGE_TECHNOLOGIES_BLOCK_LIST_ANTI_SPOOFING_TITLE" />,
    description: (
      <I18n id="MAIN_PAGE_TECHNOLOGIES_BLOCK_LIST_ANTI_SPOOFING_DESCRIPTION" />
    )
  },
  {
    id: "emotionRecognition",
    title: (
      <I18n id="MAIN_PAGE_TECHNOLOGIES_BLOCK_LIST_EMOTION_RECOGNITION_TITLE" />
    ),
    description: (
      <I18n id="MAIN_PAGE_TECHNOLOGIES_BLOCK_LIST_EMOTION_RECOGNITION_DESCRIPTION" />
    )
  },
  {
    id: "lipReading",
    title: <I18n id="MAIN_PAGE_TECHNOLOGIES_BLOCK_LIST_LIP_READING_TITLE" />,
    description: (
      <I18n id="MAIN_PAGE_TECHNOLOGIES_BLOCK_LIST_LIP_READING_DESCRIPTION" />
    )
  }
];

export const TechnologiesBlock = () => (
  <div className="container container--blue technologies-block">
    <div className="technologies-block__title">
      <I18n id="MAIN_PAGE_TECHNOLOGIES_BLOCK_TITLE" />
    </div>
    <div className="technologies-block__list hide-tablet">
      {technologies.map((technology, index) => (
        <Fragment key={technology.id}>
          <span
            className="technologies-block__list-title"
            data-tip="technology"
            data-for={technology.id}
          >
            {technology.title}
            {index !== technologies.length - 1 ? ", " : ""}
          </span>
          <ReactTooltip
            className="technologies-block__list-tooltip"
            id={technology.id}
          >
            {technology.description}
          </ReactTooltip>
        </Fragment>
      ))}
    </div>
    <div className="technologies-block__mobile-list show-tablet">
      {technologies.map((technology) => (
        <div key={technology.id} className="technologies-block__mobile-list-item">
          <div className="technologies-block__mobile-list-title">
            {technology.title}
          </div>
          <div className="technologies-block__mobile-list-description">
            {technology.description}
          </div>
        </div>
      ))}
    </div>
    <div className="technologies-block__subtitle">
      <I18n id="MAIN_PAGE_TECHNOLOGIES_BLOCK_SUBTITLE" />
    </div>
  </div>
);
