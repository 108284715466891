import React, { useEffect } from "react";
import ReactGA from "react-ga";

const trackingCode =
  window.location.host === 'bitnag.com' ? 'UA-146918928-2' : 'UA-146918928-1';

ReactGA.initialize(trackingCode);

export const withGATracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  return props => {
    useEffect(
      () => trackPage(`${props.location.pathname}${props.location.search}`),
      [props.location.pathname, props.location.search]
    );

    return <WrappedComponent {...props} />;
  };
};
